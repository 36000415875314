import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  
  /*
  con la línea de código a continuacion
  eliminamos los console.log en producción
  */
  if(window){
    window.console.log=()=>{};
  }
  
  enableProdMode();
} else {
  environment.dev = true;
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
