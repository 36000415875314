import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NopageFoundComponent } from './core/components/nopage-found/nopage-found.component';


const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    loadChildren: () => import('./core/layouts/layouts.module').then(m => m.LayoutsModule)
  },
  {
    path: '404',
    component: NopageFoundComponent
  },
  {
    path: '**',
    component: NopageFoundComponent
  }

];

@NgModule({
  imports:
  [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
