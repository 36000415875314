import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoginForm } from '../interfaces/login-form.interface';
import { Usuario } from '../interfaces/login-respuesta.interface';
import { EnvConfigService } from 'src/app/services/env-config.service';
// import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root',
})
export class UsuarioService {
  private API_URL = environment.API_URL;
  usuario?: Usuario;
  ejecutarRefresh: boolean = true;
  intervalID: any = null;

  constructor(
    private http: HttpClient,
    private router: Router,
    private envConfigService: EnvConfigService
  ) {
    envConfigService.initializeEnv();
    this.API_URL = envConfigService.apiUrl;
  }

  canDoSomething(): boolean {
    this.API_URL = '';
    this.API_URL = this.envConfigService.apiUrl;
    console.log('API_URL candosomthing', this.API_URL);
    return this.API_URL ? true : false;
  }

  refreshToken(): Observable<boolean> | null {
    if (!this.API_URL) return null;
    const refresh_token = localStorage.getItem('refresh_token') || '';
    const url = this.API_URL + `/token/refresh`;

    const refresh = {
      refresh_token: refresh_token,
    };
    return this.http.post(url, refresh).pipe(
      tap((resp: any) => {
        this.guardarStorage(resp.token, resp.refresh_token, resp.data);
      }),
      map((resp) => true)
      // catchError(err => of(false))
    );
  }

  guardarStorage(token: string, refresh_token: string, usuario: Usuario) {
    localStorage.setItem('token', token);
    localStorage.setItem('refresh_token', refresh_token);
    localStorage.setItem('usuario', JSON.stringify(usuario));
  }

  removeStorage() {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('usuario');
    localStorage.removeItem('urldebusqueda');
  }

  login(formdata: LoginForm): Observable<any> | null {
    if (!this.API_URL) return null;
    console.log('API_URL login', this.API_URL);
    console.log(formdata);
    const url = this.API_URL + `/login_check`;
    return this.http.post(url, formdata).pipe(
      tap((resp: any) => {
        this.guardarStorage(resp.token, resp.refresh_token, resp.data);
      })
    );
  }

  logout(): void {
    this.removeStorage();
    this.router.navigateByUrl('/auth/login');
  }

  verificarCaducudadToken(): void {
    let token = localStorage.getItem('token')!;

    if (token) {
      let payload = JSON.parse(atob(token!.split('.')[1]));

      let expirado = this.expirado(payload.exp);

      if (expirado) {
        console.log('LOGIN EXPIRADO');
        this.logout();
        return;
      }

      // Asegurarse de limpiar un intervalo existente antes de crear uno nuevo
      if (this.intervalID !== null) {
        clearInterval(this.intervalID);
        this.intervalID = null;
      }

      this.intervalID = setInterval(() => {
        if (this.canDoSomething()) {
          this.verificaRenueva(payload.exp);
          clearInterval(this.intervalID);
          this.intervalID = null;
          return;
        }
      }, 1000);
    }
  }

  verificaRenueva(fechaExp: number) {
    let tokenExp = new Date(fechaExp * 1000);
    let ahora = new Date();

    ahora.setTime(ahora.getTime() + 30 * 60 * 1000);

    // console.log('Hora del Token', tokenExp);
    // console.log('Hora actual', ahora);

    if (this.ejecutarRefresh) {
      if (tokenExp.getTime() < ahora.getTime()) {
        this.ejecutarRefresh = false;

        this.refreshToken()?.subscribe(
          (respuesta) => {
            this.ejecutarRefresh = true;
          },
          (err) => {
            this.ejecutarRefresh = true;
            // console.log(err);
            // Swal.fire({
            //   position: 'top-end',
            //   icon: 'error',
            //   title: 'Refresh Token',
            //   html: 'Tenemos problemas al refrescar el token.',
            //   showConfirmButton: false,
            //   timer: 4000
            // })
          }
        );
      }
    }
  }

  expirado(fechaExp: number) {
    // getTime nos llega en milisegundos por eso lo dividimos entre 1000
    let ahora = new Date().getTime() / 1000;
    if (fechaExp < ahora) {
      return true;
    } else {
      return false;
    }
  }

  changePassword(formdata: LoginForm) {
    console.log(formdata);
    const url = this.API_URL + `/users/change-password`;
    return this.http.post(url, formdata);
  }

  getUsuariosEmisoresDeSalidas(): Observable<Usuario[]> {
    const url = this.API_URL + `/user/salidas`;
    return this.http.get<Usuario[]>(url);
  }
}
